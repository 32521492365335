import React from "react";
import "./ButtonIcon.scss";
import PropTypes from "prop-types";

const BUTTON_ICON_SIZES = {
	NORMAL: "normal",
};

const ButtonIcon = props => {
	const { size = BUTTON_ICON_SIZES.NORMAL, onClick, className, children } = props;

	return (
		<div onClick={onClick} className={`button-icon ${size} ${className}`}>
			{children}
		</div>
	);
};

ButtonIcon.propTypes = {
	size: PropTypes.string,
	onClick: PropTypes.func,
	className: PropTypes.string,
	children: PropTypes.any,
};

export default ButtonIcon;
export { BUTTON_ICON_SIZES };
