import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { MonthView } from "react-calendar";
import { FormattedDate } from "react-intl";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";

const CalendarMonth = ({
	month,
	selectedDate,
	handleDateClick,
	departureDateMax,
	departureDateMin,
}) => {
	const monthValue = Number(month.split("/")[0]);
	const yearValue = Number(month.split("/")[1]);

	const activeStartDate = new Date(yearValue, monthValue, 1);

	let departureDateMaxUTC = new Date(departureDateMax);
	departureDateMaxUTC = new Date(
		departureDateMaxUTC.getFullYear(),
		departureDateMaxUTC.getMonth(),
		departureDateMaxUTC.getDate(),
		0,
		0,
		0
	);

	let departureDateMinUTC = new Date(departureDateMin);
	departureDateMinUTC = new Date(
		departureDateMinUTC.getFullYear(),
		departureDateMinUTC.getMonth(),
		departureDateMinUTC.getDate(),
		0,
		0,
		0
	);

	const getTileClassName = useCallback(
		({ date, view }) => {
			if (view === "month") {
				if (isBefore(date, departureDateMinUTC) || isAfter(date, departureDateMaxUTC)) {
					return "date-picker-calendar-tile__tile date-picker-calendar-tile__tile--disabled";
				}
			}
			return "date-picker-calendar-tile__tile";
		},
		[departureDateMinUTC, departureDateMaxUTC]
	);

	return (
		<div className="datepicker-calendar-month">
			<div className="datepicker-calendar-month__month">
				<FormattedDate
					timeZone="UTC"
					value={new Date(yearValue, monthValue, 15, 15, 0, 0)} // 15, 15, 0, 0 pour éviter bug sur win7 ie11 décalage mois
					month="long"
					year="numeric"
				/>
			</div>
			<MonthView
				value={[selectedDate, selectedDate]}
				activeStartDate={activeStartDate}
				tileClassName={getTileClassName}
				onClick={handleDateClick}
			/>
		</div>
	);
};

CalendarMonth.propTypes = {
	month: PropTypes.string,
	selectedDate: PropTypes.object,
	handleDateClick: PropTypes.func,
	departureDateMin: PropTypes.number,
	departureDateMax: PropTypes.number,
};

CalendarMonth.defaultProps = {
	month: "",
	selectedDate: new Date(),
};

export default React.memo(CalendarMonth);
