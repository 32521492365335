import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import { connect } from "react-redux";
import { STRATEGY } from "app/constants";

const BookingPrivateRoute = ({ authPath, strategy, isAuthenticated, children, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props => {
				const childrenWithProps = React.Children.map(children, child => {
					return React.cloneElement(child, props);
				});
				// TODO ROUTER isAuthenticated renvoie toujours false en SSR
				return isAuthenticated || strategy === STRATEGY.TRANSACTION_FIRST ? (
					childrenWithProps
				) : (
					<Redirect
						to={{
							pathname: authPath,
							search: `?onauthsuccess=${props.location.pathname}`,
						}}
					/>
				);
			}}
		/>
	);
};

function mapStateToProps(state) {
	return { isAuthenticated: isAuthenticated(state) };
}

export default connect(mapStateToProps)(BookingPrivateRoute);

BookingPrivateRoute.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired,
	strategy: PropTypes.string,
	authPath: PropTypes.string,
};
